import { render, staticRenderFns } from "./PcaRegionConnected.vue?vue&type=template&id=796a03c3&scoped=true&"
import script from "./PcaRegionConnected.vue?vue&type=script&lang=js&"
export * from "./PcaRegionConnected.vue?vue&type=script&lang=js&"
import style0 from "./PcaRegionConnected.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./PcaRegionConnected.vue?vue&type=style&index=1&id=796a03c3&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "796a03c3",
  null
  
)

export default component.exports