<template>
<div>
  <component :is="'b-card'">
    <!-- Form: General Info Form -->
    <validation-observer ref="simpleRules">
      <b-form class="mt-1">
        <b-row>
          <!-- Field: title -->
          <b-col cols="12" md="8" lg="8">
            <b-form-group :label="$t('Title')" label-for="title">
              <validation-provider #default="{ errors }" rules="required" name="Title">
                <b-form-input id="title" v-model="itemData.title" :state="errors.length > 0 ? false:null" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Active -->
          <b-col cols="12" md="6" lg="4">
            <b-form-group
              label=""
              label-for="contact-options"
              label-class="mb-1">
              <div class="demo-inline-spacing">
                <b-form-checkbox
                  v-model="itemData.isActive"
                  class="custom-control-primary">
                  {{ $t('Active')}}
                </b-form-checkbox>
              </div>
            </b-form-group>
          </b-col>
          <!-- Field: PCA Source Data -->
          <b-col cols="12" md="4" lg="4">
            <b-form-group :label="$t('PCA Source Data')" label-for="pcaSourceDataId">
              <validation-provider #default="{ errors }" rules="required" name="PCA Source Data">
                <v-select
                  v-model="itemData.pcaSourceDataId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="sourceDataList"
                  :reduce="(val) => val.id"
                  :clearable="false"
                  :state="errors.length > 0 ? false:null"
                  label="sourceTitle"
                  input-id="pcaSourceDataId"
                  placeholder="Select PCA Source Data"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Field: Region Grup -->
          <b-col cols="12" md="4" lg="4">
            <b-form-group :label="$t('Region Grup')" label-for="presetRegionGrupId">
              <validation-provider #default="{ errors }" rules="required" name="Region Grup">
                <v-select
                  v-model="itemData.presetRegionGrupId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="regionGrupList"
                  :reduce="(val) => val.id"
                  :clearable="false"
                  :state="errors.length > 0 ? false:null"
                  label="grupName"
                  input-id="presetRegionGrupId"
                  placeholder="Select Region Grup"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          
          <b-col cols="12" md="6" lg="6">
            <b-form-group :label="$t('Order Number')" label-for="orderNumber">
              <validation-provider #default="{ errors }" name="orderNumber">
                <b-form-input id="orderNumber" v-model="itemData.orderNumber" :state="errors.length > 0 ? false:null" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- <b-col cols="12" md="6" lg="6">
            <b-form-group :label="$t('Latitude')" label-for="originLatitude">
              <validation-provider #default="{ errors }" name="originLatitude">
                <b-form-input id="originLatitude" v-model="itemData.originLatitude" :state="errors.length > 0 ? false:null" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" lg="6">
            <b-form-group :label="$t('Longitude')" label-for="originLongitude">
              <validation-provider #default="{ errors }" name="originLongitude">
                <b-form-input id="originLongitude" v-model="itemData.originLongitude" :state="errors.length > 0 ? false:null" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col> -->
        </b-row>

        <b-row class="mt-2">
          <b-col>
            <save-button 
              :onClickSaveAndClose="saveRegion.bind(this, true)"
              :onClickSave="saveRegion.bind(this, false)"
              />
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </component>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, email, min, max,
} from '@validations'

import { heightFade } from '@core/directives/animations'
import AlertService from '@/common/alert.service'

import SaveButton from '@/components/SaveButton.vue'
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'

export default {
  components: {
    SaveButton,
    ValidationProvider,
    ValidationObserver,
  },

  directives: {
    'height-fade': heightFade,
  },

  data() {
    return {
      required,
      min,
      max,

      sourceDataList: [],
      itemData: {
        id: 0,
        title: null,
        description: null,
        sourceDataId: null,
        pcaSourceDataId: null,
        isActive: true,
        presetRegionType: 2,
        orderNumber: 0,
        originLatitude: '44.312223',
        originLongitude: '30.046882',
      },
    }
  },

  methods: {
    fetchItem() {
      const paramId = this.$router.currentRoute.params.id;
      if (parseInt(paramId, 10) !== 0) {
        this.$store.dispatch('presetRegionManagement/fetchPresetRegion', { id: paramId })
          .then(response => {
            if (response.statusCode === 200) {
              this.itemData = response.result;
            } else {
              AlertService.error(this, response.message)
            }
          })
      }
    },

    saveRegion(isClose) {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$store.dispatch('presetRegionManagement/savePresetRegion', this.itemData)
            .then(response => {
              if (response.statusCode === 200) {
                this.$toast({
                  component: ToastificationSuccess,
                  position: 'top-right',
                  props: { text: response.message },
                })
                if (isClose) {
                  this.$router.go(-1)
                } else {
                  this.itemData = response.result;
                  this.$router.push({ name: 'manage-pca-region-save', params: { id: response.result.id } });
                }
              } else {
                AlertService.error(this, response.message)
              }
            });
        }
      })
    },

    loadSourceDatas() { 
      this.$store.dispatch('sourceDataManagement/fetchSourceDatasByType', { type: 5 })
        .then(response => {
          if (response.statusCode === 200) {
              this.sourceDataList = response.result;
            } else {
              AlertService.error(this, response.message)
            }
        });
    },

    loadRegionGrups() { 
      this.$store.dispatch('presetRegionGrupManagement/fetchPresetRegionPcaGrupList', {});
    },

    loadRegionList() {
      this.$store.dispatch('presetRegionManagement/fetchPresetRegionList', {});
    },
  },

  computed: {
    regionGrupList() {
      return this.$store.getters['presetRegionGrupManagement/getPcaRegionGrupList'];
    },

    presetRegionList() {
      return this.$store.state.presetRegionManagement.presetRegionList.filter(x => x.presetRegionType === 1);
    },
  },

  created() {
    this.loadSourceDatas();
    this.fetchItem();
    this.loadRegionGrups();
    this.loadRegionList();
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
